<template>
  <footer>
    <div class="foot-nav-container pc_padding">
      <div class="footer-about">
        <div class="footer-item-title">
          About us
        </div>
        <div class="footer-item-content">
          We are committed to providing you
          with concise and useful online tools to enrich your life and work
        </div>
      </div>
      <div class="footer-side-row">
        <div class="footer-side-resource">
          <div class="footer-item-title">
            Resources
          </div>
          <div class="footer-item-content resource">
            <div class="footer-item-content-item cursor" @click="goto('blogs')">
              Blogs
            </div>
            <div class="footer-item-content-item cursor" @click="goto('qa')">
              Q&A
            </div>
            <div class="footer-item-content-item cursor" @click="goto('terms')">
              Terms of services
            </div>
            <div class="footer-item-content-item cursor" @click="goto('privacy')">
              Privacy policy
            </div>
          </div>
        </div>
        <div class="footer-side-contact">
          <div class="footer-item-title">
            Contact us
          </div>
          <div class="footer-item-content">
            <a class="footer-item-content-item"
              href="mailto:AnswerBookhub.support@gmail.com">AnswerBookhub.support@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
    <div class="corner-module pc_padding">
      All answers are generated by ChatGPT and are for entertainment purposes only <br> Copyright ©
      2024 AnswerBookhub. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      deviceType: 'deviceType',
    }),
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      this.$router.push({ name: where });
    },
  },
};
</script>
